<template>
  <div>
    <form-validation @submit="submitForm">
      <b-row>
        <b-col sm="12">
          <form-input-number label="Volume Max (m3)" v-model="volume_max" :rules="{required: true}"></form-input-number>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <form-input-number label="Berat Max (kg)" v-model="weight_max" :rules="{required: true}"></form-input-number>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <form-input-number label="Volume Sekarang (m3)" v-model="current_volume" readonly></form-input-number>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <form-input-number label="Berat Sekarang (kg)" v-model="current_weight" readonly></form-input-number>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3" sm="12">
          <button-save :isloading="isloading"></button-save>
          <button-back class="ml-1" :to="{name:'companies'}"></button-back>
        </b-col>
      </b-row>
    </form-validation>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'

export default {
  data(){
    return {
      isloading: false,
      id_branch: this.$route.params.id,
      name: "",
      volume_max: 0,
      weight_max: 0,
      current_volume: 0,
      current_weight: 0,
    }
  },
  components:{
    FormValidation,
    ButtonSave,
    ButtonBack,
    FormInputNumber
  },
  methods:{
    ...mapActions({
      dispatchShowC: 'companies/show',
      dispatchShow: 'companies/getThreshold',
      dispatchStore: 'companies/storeThreshold',
    }),
    async getData(){
      try {
        const id = this.$route.params.id
        Promise.all([
          this.dispatchShow({id}),
          this.dispatchShowC(id)
        ]).then(result => {
          const data = result[0]
          const branch = result[1]
          this.name = branch.name
          this.volume_max = data.volume_max
          this.weight_max = data.weight_max
          this.current_volume = data.current_volume
          this.current_weight = data.current_weight
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    async submitForm(){
      this.isloading = true
      const {id_branch,volume_max,weight_max,name} = this
      let params = {id_branch,volume_max,weight_max,name}

      try {
        await this.dispatchStore(params);
        this.getData()
        this.toastSuccess("OK","Data berhasil disimpan!")
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>